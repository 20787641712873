import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'
import FilterStatus from '../components/service/filterStatus'

const FilterStatusPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.filterstatus.title' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <FilterStatus />
    </div>
  </>
)

export default injectIntl(FilterStatusPage)
