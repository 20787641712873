import React, { Component } from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import axios from '../axios'

class FilterStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedData: [],
    }
  }

  getFilterStatus() {
    axios
      .get('/get/filter_status', {})
      .then(res => {
        const data = res.data
        this.setState({
          fetchedData: data,
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  clearStatus = () => {
    axios
      .post('/post/filter_status_reset', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          filterreset: true,
        },
      })
      .then(res => {
        console.log(res)
        this.getFilterStatus()
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidMount() {
    this.getFilterStatus()
  }

  render() {
    const { fetchedData } = this.state
    return (
      <>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.filterstatus.title' />
        </div>
        <div className='interfaceRow'>
          <div className='quart'>
            <label htmlFor='filterstatus'>
              <FormattedMessage id='pages.filterstatus.title' />:
            </label>
          </div>
          <div className='maxFive'>{fetchedData.filterpercentage}</div>
          <div className='quart'>&nbsp;&#37;</div>
        </div>
        <div className='interfaceRow'>
          <div className='quart'>
            <label htmlFor='filterruntime'>
              <FormattedMessage id='pages.filterstatus.filterruntime' />:
            </label>
          </div>
          <div className='maxFive'>{fetchedData.filterhrs}&nbsp;</div>
          <div className='quart'>
            <FormattedMessage id='pages.filterstatus.hours' />
          </div>
        </div>
        <div className='interfaceRow justifyCenter'>
          <button onClick={this.clearStatus}>
            <FormattedMessage id='pages.filterstatus.resetbutton' />
          </button>
        </div>
      </>
    )
  }
}

export default FilterStatus
